import React, { useEffect, useState } from "react";

import Loading from "~/components/Loading";

import * as MeActions from "~/store/modules/me/actions";
import { useDispatch, useSelector } from "react-redux";

import { removeToken } from "~/services/token";
import { removeAuthenticatedUrl } from "~/services/authenticatedUrl";

import "../../components/Login/Login.scss";

export default function UpdateSystem() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const logo = useSelector((store) => store.configs.website.logo);

  useEffect(() => {
    removeToken();
    dispatch(MeActions.me({}));
    removeAuthenticatedUrl();

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="container-fluid-home">
      <div className="login-container">
        <div className="login">
          <div className="logo">
            <img alt="" src={logo} />
          </div>

          {loading ? (
            <div className="text">
              <h1>Atualizando sistema...</h1>
              <Loading size={3} />
            </div>
          ) : (
            <div className="text">
              <h1>Sistema atualizado</h1>
              <p>Acesse novamente sua conta e clique em "Assembleias".</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
