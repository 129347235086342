import produce from "immer";

const INITIAL_STATE = {
  website: "",
  captcha_enable: false,
  assembly_hybrid_enabled: false,
  bottom_bar_content: null
};

export default function configs(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@configs/configs": {
        draft.website = action.payload.website;
        break;
      }
      case "@configs/captcha_enable": {
        draft.captcha_enable = action.payload.captcha_enable;
        break;
      }
      case "@configs/assembly_hybrid_enabled": {
        draft.assembly_hybrid_enabled = action.payload.assembly_hybrid_enabled;
        break;
      }
      case "@configs/bottom_bar_content": {
        draft.bottom_bar_content = action.payload.bottom_bar_content;
        break;
      }
      default:
    }
  });
}
