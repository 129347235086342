import React, { useEffect, lazy, Suspense, useState } from "react";

import "./Authentication.scss";

import api from "~/services/api";

import useQuery from "~/hooks/use-query";
import { setToken, removeToken } from "~/services/token";
import {
  setAuthenticatedUrl,
  removeAuthenticatedUrl,
} from "~/services/authenticatedUrl";

import Loading from "~/components/Loading";

import { useDispatch } from "react-redux";

import { useParams, useHistory } from "react-router-dom";
import * as MeActions from "~/store/modules/me/actions";
import { getCurrentDomain } from "~/utils";

const Navbar = lazy(() => import("~/components/Navbar"));
const LoginModal = lazy(() => import("~/components/Login"));

export default function Authentication() {
  const { token } = useParams();
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const [companyToken, setCompanyToken] = useState("");
  const [error, setError] = useState("");
  const [redirectState, setRedirectState] = useState('');

  useEffect(() => {
    removeToken();
    dispatch(MeActions.me({}));

    const redirect = query.has("redirect");
    setRedirectState(redirect)
    if (!redirect) {
      let url = window.location.origin + window.location.pathname;
      if (window.location.search) {
        url += `${window.location.search}&redirect=true`;
      } else {
        url += "?redirect=true";
      }
      return window.location.href = url;
    }

    const domain = getCurrentDomain();

    api
      .post(`/v1/livestreams/login`, {
        token,
        domain,
      })
      .then(({ data }) => {
        const { token, has_representative } = data;

        if (query.get("logoff")) {
          removeAuthenticatedUrl();
        } else {
          setAuthenticatedUrl();
        }

        if (has_representative) {
          setCompanyToken(token);
        } else {
          setToken(token);
          api
            .get(`/v1/me`)
            .then(({ data: me }) => {
              dispatch(MeActions.me(me));

              const livestreamGuid = query.get("livestream_guid");
              const access_type = query.get("access_type");

              let redirectUrl = "/";

              if (livestreamGuid)
                redirectUrl = `/lives/${livestreamGuid}?type=presential&access_type=${access_type}`;

              history.replace(redirectUrl);
            })
            .catch(() => {
              history.replace(`/`);
            });
        }
      })
      .catch((err) => {
        setError(
          err?.response?.data?.message ||
            "Não conseguimos realizar a autenticação."
        );
      });
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  return error ? (
    <div className="container-fluid-home">
      <Suspense fallback={<div />}>
        <Navbar />
      </Suspense>
      <div className="modal-authentication">
        <div>
          <h3>{error}</h3>
        </div>
      </div>
    </div>
  ) : companyToken ? (
    <div className="container-fluid-home">
      <Navbar />
      <div className="container-live-box-list">
        <Suspense fallback={<div />}>
          <LoginModal companyToken={companyToken} />
        </Suspense>
      </div>
    </div>
  ) : !redirectState ? (
    null
  ) : (
    <div className="container-fluid-home">
      <Suspense fallback={<div />}>
        <Navbar />
      </Suspense>
      <div className="modal-authentication">
        <div>
          <Loading size={5} />
          <h3>Autenticando...</h3>
        </div>
      </div>
    </div>
  );
}
