export function configs(website) {
  return {
    type: "@configs/configs",
    payload: {
      website,
    },
  };
}

export function captchaEnable(captcha_enable) {
  return {
    type: "@configs/captcha_enable",
    payload: {
      captcha_enable,
    },
  };
}

export function assemblyHybridEnabled(assembly_hybrid_enabled) {
  return {
    type: "@configs/assembly_hybrid_enabled",
    payload: {
      assembly_hybrid_enabled,
    },
  };
}

export function setBottomBarContent(bottom_bar_content) {
  return {
    type: "@configs/bottom_bar_content",
    payload: {
      bottom_bar_content,
    },
  };
}
