import React, { Suspense, lazy, useEffect, useState } from "react";

import "./Home.scss";

import api from "~/services/api";
import { getToken } from "~/services/token";
import { getCurrentDomain } from "~/utils";

import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import config from "~/config";
import moment from "moment";
import Collapse from "~/components/Collapse";
import customer from "~/customer";
import * as MeActions from "~/store/modules/me/actions";
import { removeToken } from "~/services/token";

const Navbar = lazy(() => import("~/components/Navbar"));
const LoginModal = lazy(() => import("~/components/Login"));

export default function Home() {
  const token = getToken();
  const dispatch = useDispatch();

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [livestreams, setLivestreams] = useState([]);
  const [myLivestreams, setMyLivestreams] = useState([]);
  const [prePolls, setPrePolls] = useState([]);
  const [posPolls, setPosPolls] = useState([]);

  useEffect(() => {
    function fetchMe() {
      const url = `/v1/me`;
      api
        .get(url)
        .then(({ data: me }) => {
          dispatch(MeActions.me(me));
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            removeToken();
            dispatch(MeActions.me({}));
            history.push(`/`);
          }
        });
    }

    if (token) fetchMe();
  }, [token]);

  const me = useSelector((store) => store.me.me);
  const logo = useSelector((store) => store.configs.website.logo);
  useEffect(() => {
    if (Object.keys(me).length > 0 && !me.name) {
      history.push("/my-account");
    }
  }, [me]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (token && me.name) {
      getLivesList();
    }
  }, [token, dispatch, me]); // eslint-disable-line react-hooks/exhaustive-deps

  function getLivesList() {
    setLoading(true);
    const domain = getCurrentDomain();
    // const domain = "assembleia-dev";
    api
      .get(`/v1/livestreams?domain=${domain}`)
      .then(({ data: livestreams }) => {
        if (livestreams.length === 1) {
          return history.push(`/lives/${livestreams[0].guid}`);
        }
        const lives = [...livestreams];
        const myLives = [];
        livestreams.forEach((live, index) => {
          if (config.showMyLivestreamsByAgencyAndGroup) {
            if (
              (live.agencies.some(
                (agency) => agency === me?.branch_info?.agency_code
              ) &&
                (live.accept_other_groups ||
                  live.groups.length === 0 ||
                  live.groups.some(
                    (group) => group === me?.branch_info?.group
                  ))) ||
              (me?.is_guest && live.accept_specific_guests)
            ) {
              lives.splice(index, 1);
              myLives.push(live);
            }
          } else {
            if (
              live.agencies.some(
                (agency) => agency === me.branch_info.agency_code
              ) ||
              (me?.is_guest && live.accept_specific_guests)
            ) {
              lives.splice(index, 1);
              myLives.push(live);
            }
          }
        });
        const otherLives = lives.reduce(
          (curr, live) => {
            const isInMyLives = myLives.findIndex(
              (mylive) => mylive.guid === live.guid
            );
            if (isInMyLives === -1) {
              if (live.pre_poll_enabled) {
                curr.prePoll.push(live);
              } else if (live.pos_poll_enabled) {
                curr.posPoll.push(live);
              } else {
                curr.lives.push(live);
              }
            }
            return curr;
          },
          { prePoll: [], posPoll: [], lives: [] }
        );

        setPrePolls(otherLives.prePoll);
        setPosPolls(otherLives.posPoll);
        setLivestreams(otherLives.lives);
        setMyLivestreams(myLives);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function renderItem(live, style) {
    return (
      <Link
        to={`/lives/${live.guid}`}
        key={live.guid}
        className={`container-live-box ${style}`}
      >
        <div className="container-live-box-preview">
          <div className="logo-container">
            <img className="logo" alt="" src={live.logo || logo} />
            <img className="logo-white" alt="" src={live.logo || logo} />
          </div>
          <div className={`status-video ${style}`}>
            <p>
              Assistir{" "}
              {`${
                live.pre_poll_enabled
                  ? config.prePollText
                    ? config.prePollText
                    : "Pré Assembleia"
                  : live.pos_poll_enabled
                  ? config.posPollText
                    ? config.posPollText
                    : "Pós Assembleia"
                  : "Assembleia"
              }`}
            </p>
          </div>
        </div>
        <div className="container-live-box-details">
          <h5>{live.name}</h5>
          <div className="container-live-box-details-info">
            <div>
              <p>
                <span className="icon icon-calendar" />
                {live.starts_at && moment(live.starts_at).format("DD/MM/YYYY")}
              </p>
              <p>
                <span className="icon icon-wall-clock" />
                {live.starts_at &&
                  live.ends_at &&
                  moment(live.starts_at).format("HH:mm") +
                    "-" +
                    moment(live.ends_at).format("HH:mm")}
              </p>
            </div>
            <div className={`status-video-mobile ${style}`}>
              <p>
                Assistir{" "}
                {`${
                  live.pre_poll_enabled
                    ? config.prePollText
                      ? config.prePollText
                      : "Pré Assembleia"
                    : live.pos_poll_enabled
                    ? config.posPollText
                      ? config.posPollText
                      : "Pós Assembleia"
                    : "Assembleia"
                }`}
              </p>
            </div>
          </div>
        </div>
      </Link>
    );
  }

  return (
    <Suspense
      fallback={
        <div className="suspense-screen-home">
          <div className="navbar-suspense"></div>
          <div className="title-suspense" />
          <div className="lives-list-suspense"></div>
        </div>
      }
    >
      <div className="container-fluid-home">
        <Navbar />
        <div className="container-live-box-list">
          {!token && (
            <Suspense fallback={<div />}>
              <LoginModal />
            </Suspense>
          )}
          {token && loading && (
            <div className="loading-container-live-box-list" />
          )}
          {token && !loading && (
            <Suspense
              fallback={<div className="loading-container-live-box-list" />}
            >
              {livestreams.length === 0 &&
                myLivestreams.length === 0 &&
                prePolls.length === 0 &&
                posPolls.length === 0 && (
                  <div className="no-live-found">
                    <div>
                      {customer === "ailos_qa" ||
                      customer === "ailos_prod" ||
                      customer === "ailos" ||
                      customer === "assembleia" ? (
                        <>
                          <p>Nenhuma assembleia disponível.</p> Para consultar a
                          agenda dos próximos eventos acesse:{" "}
                          <a href="https://assembleias.ailos.coop.br/">
                            assembleias.ailos.coop.br
                          </a>
                          .
                        </>
                      ) : (
                        "Nenhuma assembleia disponível"
                      )}
                    </div>
                  </div>
                )}

              {myLivestreams.length > 0 && (
                <div className="title-home">
                  <h2>Minhas Assembleias</h2>
                </div>
              )}
              {myLivestreams.map((live) => {
                const style = live.pre_poll_enabled
                  ? "pre-polls"
                  : live.pos_poll_enabled
                  ? "pos-polls"
                  : "todays-livestreams";
                return renderItem(live, style);
              })}

              {!config.showMyLivestreamsOnly && prePolls.length > 0 && (
                <Collapse>
                  {(isOpen, handleCollapse) => (
                    <>
                      <div className="title-home">
                        <h2>
                          <span onClick={handleCollapse}>
                            {config.prePollTitle
                              ? config.prePollTitle
                              : `Pré Assembleia`}
                            <span
                              className={`icon-${isOpen ? "up" : "down"}`}
                            />
                          </span>
                        </h2>
                      </div>
                      {isOpen && (
                        <>
                          {prePolls.map((live) =>
                            renderItem(live, "pre-polls")
                          )}
                        </>
                      )}
                    </>
                  )}
                </Collapse>
              )}

              {!config.showMyLivestreamsOnly && livestreams.length > 0 && (
                <Collapse>
                  {(isOpen, handleCollapse) => (
                    <>
                      <div className="title-home">
                        <h2>
                          <span onClick={handleCollapse}>
                            Assembleias de Hoje
                            <span
                              className={`icon-${isOpen ? "up" : "down"}`}
                            />
                          </span>
                        </h2>
                      </div>
                      {isOpen && (
                        <>
                          {livestreams.map((live) =>
                            renderItem(live, "todays-livestreams")
                          )}
                        </>
                      )}
                    </>
                  )}
                </Collapse>
              )}

              {!config.showMyLivestreamsOnly && posPolls.length > 0 && (
                <Collapse>
                  {(isOpen, handleCollapse) => (
                    <>
                      <div className="title-home">
                        <h2>
                          <span onClick={handleCollapse}>
                            Pós Assembleia
                            <span
                              className={`icon-${isOpen ? "up" : "down"}`}
                            />
                          </span>
                        </h2>
                      </div>
                      {isOpen && (
                        <>
                          {posPolls.map((live) =>
                            renderItem(live, "pos-polls")
                          )}
                        </>
                      )}
                    </>
                  )}
                </Collapse>
              )}
            </Suspense>
          )}
        </div>
      </div>
    </Suspense>
  );
}
