import React, { useEffect, useState } from "react";

import {
  Switch,
  Route,
  useLocation,
  Redirect,
  useHistory,
} from "react-router-dom";

import ReactGA from "react-ga4";

import Home from "~/pages/Home";
import MyAccount from "~/pages/MyAccount";
import Livestream from "~/pages/Livestream";
import Authentication from "~/pages/Authentication";
import UpdateSystem from "~/pages/UpdateSystem";
import Landingpage from "~/pages/LandingPage/Landingpage";
import Delegates from "~/pages/Delegates";

import definition from "~/config";
import api from "~/services/api";
import { getCurrentDomain } from "~/utils";

export default function Routes() {
  const location = useLocation();
  const history = useHistory();
  const [path, setPath] = useState(null);
  const [applyRoute, setApplyRoute] = useState(false);
  const [landingPageInfo, setLandingPageInfo] = useState({});
  ReactGA.initialize(definition.googleTrackingId);

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    if (location.pathname !== path) {
      getPath(getCurrentDomain());
    }
  }, [path]); // eslint-disable-line react-hooks/exhaustive-deps

  async function getPath(domain) {
    try {
      const { data } = await api.get(`/v1/landing-page/${domain}`);
      if (data.redirect_to !== location.pathname) {
        const ends_at = new Date(data.ends_at);
        const now = new Date();
        if (ends_at > now) {
          setLandingPageInfo(data);
          setPath(`/${data.redirect_to}`);
          setApplyRoute(true);
          history.replace(data.redirect_to);
        } else {
          // history.replace(data.redirect_to_after_date);
          setApplyRoute(false);
        }
      }
    } catch (error) {
      setApplyRoute(false);
    }
  }
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/lives/:guid" component={Livestream} />
      <Route path="/my-account" component={MyAccount} />
      <Route path="/acessar/:token" component={Authentication} />
      <Route path="/update-system" component={UpdateSystem} />
      <Route
        exact
        path="/admin"
        component={() => {
          window.location.href = "/admin/";
          return null;
        }}
      />
      <Route exact path="/delegados" component={Delegates} />

      {applyRoute && (
        <Route
          path={path}
          component={() => <Landingpage info={landingPageInfo} />}
        />
      )}
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}
