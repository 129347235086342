import React from "react";
import { LandingPageProvider } from "./LandingPageContext";
import Uniprime from "./Uniprime";

import parse from "html-react-parser";

function Landingpage({ info }) {
  return (
    <LandingPageProvider>
      {info.branch === 649 ? (
        <Uniprime
          type="Ordinária"
          branch={info.branch}
        />
      ) : (
        info.content && <div>{parse(info.content)}</div>
      )}
    </LandingPageProvider>
  );
}

export default Landingpage;
